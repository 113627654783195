<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './linerpAppConfigFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'linerp-app-config-form',
    meta: {},
  }),
  methods: {
    async updateLinerpDefaultRichMenu(photo) {
      try {
        await this.$api.collection.lineApi.updateLinerpDefaultRichMenu({ photo })
        this.$snotify.success(null, 'Rich Menu更新成功')
      } catch (error) {
        console.warn(error.headers.reason)
        this.$snotify.error('請查看主控台錯誤訊息', 'Rich Menu更新失敗')
      }
    },
    async updateLinerpProviderRichMenu(photo) {
      try {
        await this.$api.collection.lineApi.updateLinerpProviderRichMenu({ photo })
        this.$snotify.success(null, 'Rich Menu更新成功')
      } catch (error) {
        console.warn(error.headers.reason)
        this.$snotify.error('請查看主控台錯誤訊息', 'Rich Menu更新失敗')
      }
    },
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi() {
      return await this.$api.collection.baseApi.appConfigRead('linerp')
    },
    async updateApi(target, formData) {
      return await this.$api.collection.baseApi.appConfigUpdate('linerp', formData)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
